
#App {


  margin: auto 0;
 background-image: url('./images/bg-01.jpg');
 width: 100%;  
 min-height: 100vh;
 display: -webkit-box;
 display: -webkit-flex;
 display: -moz-box;
 display: -ms-flexbox;
 /* display: flex;
 flex-wrap: wrap;
 justify-content: center;
 align-items: center; */
 padding: 15px;
 background-repeat: no-repeat;
 background-position: center;
 background-size: cover;

}


