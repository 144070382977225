html,
body {
  height: 100%;
}
body {
  margin: 0;
  background: linear-gradient(45deg, #49a09d, #5f2c82);
  font-family: sans-serif;
  font-weight: 100;
}
.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
table {
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 0 auto;
}
th,
td {
  padding: 15px;
  /* background-color: white; */
  background-color: rgba(255, 255, 255, 0.4); 
  color: yellow;
  text-align: center;
}
tr,td {
  font-size: large;
  font-weight: 100;
  padding:10px;
border-style: hidden;
border-width: 1px;
border-color: whitesmoke;
border-right: none;
border-left: none;
}
th {
  
  text-align: center;
  color:white;
  font-size: medium;
  border-style:  hidden;
  padding-left:0px
}
thead th {
  background-color: #55608f;
}
tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
tbody td {
  position: relative;
}
tbody td:hover:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: -9999px;
  bottom: -9999px;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: -1;
}
